import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ShipperService {
    readonly baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) { }

    public createShipper(entity: object) {
        return this.http.post(`${this.baseUrl}api/shippers`, entity, { withCredentials: true });
    }
    public getUrlFilterShipers(urlFilter: string) {
        return this.http.get(`${this.baseUrl}api/shippers${urlFilter}`, { withCredentials: true });
    }
    public deleteShipper(deleteEv: string) {
        return this.http.delete(`${this.baseUrl}api/shippers/${deleteEv}`, { withCredentials: true })
    }
    public getShipper(id: string) {
        return this.http.get(`${this.baseUrl}api/shippers/${id}`, { withCredentials: true });
    }
    public updateShipper(entity: object, id: string) {
        return this.http.put(`${this.baseUrl}api/shippers/${id}`, entity, { withCredentials: true })
    }
    public getAllShippers() {
        return this.http.get(`${this.baseUrl}api/shippers`, { withCredentials: true });
    }
    public updateForwarderRequest(entity: object, id: any) {
        return this.http.post(`${this.baseUrl}api/forwarder_requests`, {
            entityName: 'Shipper',
            entityId: id,
            payload: entity
        }, { withCredentials: true })
    }
    public getForwarderRequrst(id: string){
        return this.http.get(`${this.baseUrl}api/forwarder_requests/${id}`, { withCredentials: true })
    }
    
}
