import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FileService {
  readonly baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) { }
  public getFile(id: string){
    return this.http.get(`${this.baseUrl}api/media_files/${id}`, { withCredentials: true })
}
}
