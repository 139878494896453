import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invalid-order',
  templateUrl: './invalid-order.component.html',
  styleUrls: ['./invalid-order.component.scss']
})
export class InvalidOrderComponent implements OnInit {
  @Input('title') title : string
  @Input('text') text : string

  constructor() { }

  ngOnInit() {
  }

}
