import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { P } from '@angular/cdk/keycodes';

@Injectable({
    providedIn: 'root'
})
export class DepartmentsService {
    readonly baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) { }

    public getDepartmentById(id: string) {
        return this.http.get(`${this.baseUrl}api/departments/${id}`, { withCredentials: true });
    }

    public getUrlFilterDepartments(urlFilter: string) {
        return this.http.get(`${this.baseUrl}api/departments${urlFilter}`, { withCredentials: true });
    }

    public deleteDepartment(deleteEv: string) {
        return this.http.delete(`${this.baseUrl}api/departments/${deleteEv}`, { withCredentials: true })
    }

    public createDepartment(department) {
        return this.http.post(`${this.baseUrl}api/departments`, department, { withCredentials: true });
    }

    public getByShipper(shipper: string) {
        return this.http.get(`${this.baseUrl}api/departments/shipper/${shipper}`, { withCredentials: true })

    }

    public updateDepartment(department, id) {
        return this.http.put(`${this.baseUrl}api/departments/${id}`, department, { withCredentials: true });
    }

    public getCoutries() {
        return this.http.get(`${this.baseUrl}api/countries`, { withCredentials: true });
    }

    public searchCountry(value){
        return this.http.get(`${this.baseUrl}api/search?entity=country&string=${value}`, { withCredentials:true })
    }

    public updateDepartmentRequest(entity: object, id: number) {
        return this.http.post(`${this.baseUrl}api/forwarder_requests`, {
            entityName: 'Department',
            entityId: id,
            payload: entity
        }, { withCredentials: true });
    }

    public createDepartmentRequest(entity: object) {
        return this.http.post(`${this.baseUrl}api/forwarder_requests`, {
            entityName: 'Department',
            payload: entity
        }, { withCredentials: true })
    }
}
