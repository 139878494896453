import { InvalidOrderComponent } from './invalid-order.component';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    InvalidOrderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    InvalidOrderComponent
  ]
})
export class InvalidOrderModule { }
